import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import message from "../../utils/mesaages";
import {SSO_LOGIN_BIZ_SOCIAL } from '../../utils/Sso';

const SSOTokkenHandle = () => {
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const { token } = useParams();

  useEffect(() => {
    if(!token){
      history.push({ pathname: "/login" });
    }else{
      handleSSOLogin(token);
    }
  }, [token]);

  const handleSSOLogin = async (acode) => {
    const res = await SSO_LOGIN_BIZ_SOCIAL(acode);
    if (res === false) {
      message.error("User Not Found");
      setTimeout(() => {
        history.push({ pathname: "/login" });
      }, 3000);
    } else {
      if (res.data.result) {
        localStorage.setItem("authToken", res.data.token);
        localStorage.setItem("userdata", JSON.stringify(res.data.userdata));
        setLoader(false);
        let pathname = "/webinars";
        
        history.push({
          pathname,
          state: {
            user: res.data.userdata,
            token: res.data.token,
          },
        });
        
      } else {
        message.error("User Not Found");
        setTimeout(() => {
          history.push({ pathname: "/login" });
        }, 3000);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-center h-100">
        <h4>You are being redirected to Coach Master, Please wait......</h4>
      </div>
    </React.Fragment>
  );
};

export default SSOTokkenHandle;
