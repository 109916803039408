import axios from '../config/axios';
import message from '../utils/mesaages';




let SSO_LOGIN = (code)=>{
    console.log('code',code)
    return new Promise((resolve, reject) => {
        axios.get('/SSO/sso-login/'+code).then((res)=>{

            resolve(res);
        }).catch((e)=>{
            console.log('err',e)
            resolve(false)
        })
       
      });
   

}


let SSO_AUTH_TOKEN_CHECK = (token)=>{
    console.log('token',token)
   
    return new Promise((resolve, reject) => {
        axios.get('/SSO/sso-token-validate/'+token).then(async (res)=>{
            if(res.data.res.success!=undefined)
            {
                console.log(res.data.res.success.data)
                if(res.data.res.success.data.logged_in==false)
                {
                    const AUTHtoken = localStorage.getItem("authToken");
                    localStorage.clear();
                    if (AUTHtoken!=undefined) {
                      let param = {
                        url: "/users/logout",
                        method: "POST",
                        data: { token: AUTHtoken },
                        headers: { "X-Auth": AUTHtoken },
                      };
                      await axios(param)
                    }
                   
                    window.location = '/login#logout'

                }
            }

            resolve(res);
        }).catch((e)=>{
            console.log('err',e)
            resolve(false)
        })
       
      });
   

}

let SSO_LOGOUT=()=>{

    
    return new Promise((resolve, reject) => {
        let ML_TOKEN = localStorage.getItem('ML_TOKEN')
        if(ML_TOKEN!=undefined && ML_TOKEN!='' && ML_TOKEN!=null)
        {
            axios.get('/SSO/sso-logout/'+ML_TOKEN).then(async (res)=>{
               
                console.log('SSOLOGOUT',res.data)
                resolve(res);
            }).catch((e)=>{
                console.log('err',e)
                resolve(false)
            })

        }
        else
        {
            resolve()

        }
       
       
      });

}

let SSO_UPDATE=({fname,lname,phone})=>{

    return new Promise((resolve, reject) => {
        let ML_TOKEN = localStorage.getItem('ML_TOKEN')
        if(ML_TOKEN!=undefined && ML_TOKEN!='' && ML_TOKEN!=null)
        {
            axios.post('/SSO/sso-update/',{first_name:fname,last_name:lname,token:ML_TOKEN,phone}).then(async (res)=>{
               
               
                resolve(res);
            }).catch((e)=>{
                console.log('err',e)
                resolve(false)
            })

        }
        else
        {
            resolve()

        }
       
       
      });

}
let SSO_LOGIN_BIZ_SOCIAL = (code)=>{
    return new Promise((resolve, reject) => {
        axios.get('/SSO/sso-login-bizsocial/'+code).then((res)=>{
            resolve(res);
        }).catch((e)=>{
            console.log('err',e)
            resolve(false)
        })
    });
}

export {
    SSO_LOGIN,
    SSO_AUTH_TOKEN_CHECK,
    SSO_LOGOUT,
    SSO_UPDATE,
    SSO_LOGIN_BIZ_SOCIAL
}

