import React, { useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import CommingSoon from "./components/Future/CommingSoon";
import moment from "moment";
// import test from "./utils/timeZoneConvert"
import { ForgotPassword, Logout, SetPassword } from "./views/auth/login";
import {
    AccountActivation,
    CoachRegistration,
} from "./views/auth/registration";
import JobseekerChangePassword from "./components/webinars/JobseekerChangePassword";
import JobseekerPaymentHistory from "./components/webinars/JobseekerPaymentHistory";
import SSOTokkenHandle from "./layouts/biz_social/SSOTokenHandle.js";

import {
    Coach,
    Consumer,
    LandingLayout,
    Login,
    RegistrationStep,
    AboutUs,
    Categories,
    ForCoaches,
    FindMyCoach,
    MatchMyCoach,
    FAQ,
    ContactUS,
    Webinar,
    Terms,
    PrivacyPolicy,
    CaliforniaPrivacy,
    CoachPublic,
    Feedback,
    Unsubscribe,
    ThankYou,
    Gdpr,
    Testfile,
} from "./layouts";
import RedirectLogin from "./layouts/RedirectLogin";
import Template from "./layouts/Template";

import { ViewPackageList } from "./views/coach/view";
import { UpdatePersonalNew } from "./views/coach/edit";
import { PriceTable } from "./components/pricetable";
import { PriceTableNew } from "./components/pricetable";

import BookSession from "./views/coach/view/BookSession";
import MainSession from "./views/coach/view/MainSession";
import BookingPayment from "./views/coach/view/BookingPayment";
import Buysubscription from "./views/coach/view/Buysubscription";

import HelmetMetaData from "./components/meta/dynamicMeta";
import ScrollUpButton from "react-scroll-up-button";
import CoachProfilePrivate from "./layouts/CoachProfilePrivate";

import { UpdateUserPersonalnew } from "./views/consumer/edit";
import ScrollToTop from "./components/scrollToTop/scrollToTop";
import ReviewList from "./views/coach/ReviewList";
import Coocki from "./layouts/Cookie";
import { ResourcesList } from "./views/coach/";
import { SSO_AUTH_TOKEN_CHECK } from "./utils/Sso";

import ReactGA from "react-ga";
import axios from "./config/axios";

import GoogleAnalyticsPageTracker from "./components/analytics/GoogleAnalyticsPageTracker";
import Dashboard from "./components/webinars/dashboard/Dashboard";
import Profile from "./components/webinars/profile/Profile";
import Articles from "./components/webinars/articles/Articles";
import Schedule from "./components/webinars/schedule/Schedule";
import Sessions from "./components/webinars/sessions/Sessions";
import WebinerLayout from "./components/webinars/WebinerLayout";
import WebinerDetails from "./components/webinars/webinar-details/WebinerDetails";
import WebinarHistory from "./components/webinars/webinar-history/WebinarHistory";
import SessionDetails from "./components/webinars/session-details/sessionDetails";
import Signup from "./components/corporate/Signup/Signup";
import Webinars_category from "./layouts/Webinar";
import CorporateLogin from "./components/corporate/Login/Login";
import CorporateDashboard from "./components/corporate/Dasboard/Dashboard";
import WebinarDetails from "./components/corporate/WebinarDetails/WebinarDetails";
import BulkContacts from "./components/corporate/BulkContacts/BulkContacts";
import Landing from "./components/corporate/Landing/Landing";
import BulkContactsDetails from "./components/corporate/BulkContacts/BulkContactsDetails";
import CorporateUserLogin from "./components/corporate/Login/UserLogin";
import UserSignup from "./components/corporate/Signup/UserSignup";
import CorporateSessions from "./components/corporate/sessions/Sessions";
import CorporateWebinar from "./components/corporate/schedule/Schedule";
import profileindex from "./components/corporate/profile/profileindex";
import MyTransaction from "./components/corporate/Mytransaction/MyTransaction";
import PageNotfound from "./components/corporate/error/PageNotfound";
import Setting from "./components/corporate/Setting/Setting";
import ForBusiness from "./layouts/ForBusiness";
import AccountTransfare from "./components/corporate/BulkContacts/AccountTransfare";
import JobSeekerRegistration from "./views/auth/registration/JobSeekerRegistraction";
import CorporateForCoaches from "./components/corporate/ForCoaches/CorporateForCoaches";
import changePassword from "./components/corporate/profile/changePassword";
import VideoRecordings from "./layouts/VideoRecordings";
import WebinarLibrary from "./components/webinars/dashboard/webinarLibrary";
import PaymentModule from "./views/auth/registration/PaymentModule";
import Supportform from "./components/webinars/support.js";
import Pricing from "./layouts/Pricing.jsx";
import WebinerDetailsPublic from "./layouts/WebinerDetailsPublic.jsx";

setInterval(async () => {
    let ML_TOKEN = localStorage.getItem("ML_TOKEN");
    if (ML_TOKEN != undefined && ML_TOKEN != "" && ML_TOKEN != null) {
        await SSO_AUTH_TOKEN_CHECK(ML_TOKEN);
    }
}, 30000);

function WebinerRoute({ component: Component, layout: Layout, ...rest }) {
    const [isExpired,setIsExpired] = useState(false);
    const [amount,setAmount] = useState(0);
    const [plan,setPlan] = useState({});
    const [user,setUser] = useState({});
    const [onTrial,setOnTrial] = useState(false);

    const checkExpiryDate = ()=>{
        axios.post('/webinar/check-expiry', {}, { headers: { "x-auth": localStorage.getItem("authToken")}, }).then((res) => {
            if(res.data.success){
                let data = JSON.parse(localStorage.getItem('userdata'))
                data.expiry_date = res.data.userExpiryDate;
                localStorage.setItem('userdata',JSON.stringify(data));
                setOnTrial(res.data.on_trial)
                setUser(res.data.user)
                if(res.data.isExpired == true){
                    setIsExpired(true);
                    setAmount(res.data.amount);
                    setPlan(res.data.plan_detail)
                }else{
                    setIsExpired(false);
                    setUser(res.data.user)
                }
            }else{
                if(res.data.status == 500){
                    window.location.href = "/login";
                    return;
                }
                setIsExpired(false);
            }
          }).catch((err) => {
            console.log(err)
          })
    }

    useEffect(function(){
        if(!localStorage.getItem("authToken")) {
            window.location.href = "/login";
            return;
        }
        checkExpiryDate();
    },[Component]);
    
    return (
        <Route
            {...rest}
            render={(props) =>
                localStorage.getItem("authToken") ? (
                    <Layout {...props}>
                        { (isExpired && ["/webinars/pay-now"].includes(rest.path) && !localStorage.getItem('ML_TOKEN'))? (
                            <PaymentModule totalAmount={amount}  plan={plan} />
                        ) : ( 
                           rest.type ? <Component user={user} plan={plan} onTrial={onTrial} isExpired={isExpired} type={rest.type} {...props} /> : <Component {...props} onTrial={onTrial} plan={plan} isExpired = {isExpired} user={user}/> 
                         )}
                    </Layout>
                ) : (
                    // <Redirect to="/login" />
                    <Layout {...props}>
                        <Component {...props} />
                    </Layout>
                )
            }
        />
    );
}

export function MainApp() {
    useEffect(() => {
        axios
            .get("/admin/settings/google_analytics_tracking_id")
            .then((res) => {
                if (res.status === 200) {
                    const data = res.data;
                    ReactGA.initialize(data.tracking_id);
                    ReactGA.pageview(window.location.pathname + window.location.search);
                }
            })
            .catch((e) => console.log(e));
    }, []);

    return (
        <>
            <ScrollToTop>
                <Coocki />

                <ScrollUpButton
                    ShowAtPosition={200}
                    style={{ width: 50, marginBottom: "70px" }}
                />
                <HelmetMetaData />
                <Switch>
                    <Route path="/comming-soon" component={CommingSoon} exact={true} />
                    {/* <Route path="/test" component={test} exact={true} /> */}
                    <Route path="/" component={LandingLayout} exact={true} />
                    <Route path="/webinars-category" component={Webinars_category} exact={true} />

                    {/* _____________WEBINER_________________ */}
                    <WebinerRoute
                        path="/webinars"
                        component={Dashboard}
                        exact={true}
                        layout={WebinerLayout}
                    />
                    <WebinerRoute
                        path="/webinars/profile"
                        component={Profile}
                        exact={true}
                        layout={WebinerLayout}
                    />
                    <WebinerRoute
                        path="/webinars/articles"
                        component={Articles}
                        exact={true}
                        layout={WebinerLayout}
                    />
                    <WebinerRoute
                        path="/webinars/pay-now"
                        component={PaymentModule}
                        exact={true}
                        layout={WebinerLayout}
                    />
                    <WebinerRoute
                        path="/webinars/sessions"
                        component={Sessions}
                        exact={true}
                        layout={WebinerLayout}
                    />
                     <WebinerRoute
                        path="/webinars/webinars-library"
                        component={WebinarLibrary}
                        exact={true}
                        layout={WebinerLayout}
                    />
                     <WebinerRoute
                        path="/webinars/recordings"
                        component={VideoRecordings}
                        exact={true}
                        layout={WebinerLayout}
                    />
                    <WebinerRoute
                        path="/webinars/careermaster-videos"
                        component={VideoRecordings}
                        exact={true}
                        type="admin_recording" 
                        layout={WebinerLayout}
                    />

                    <WebinerRoute
                        path="/webinars/support"
                        component={Supportform}
                        exact={true}
                        layout={WebinerLayout}
                    />
                    <WebinerRoute
                        path="/webinars/change-password"
                        component={JobseekerChangePassword}
                        exact={true}
                        layout={WebinerLayout}
                    />
                    <WebinerRoute
                        path="/webinars/payment-history"
                        component={JobseekerPaymentHistory}
                        exact={true}
                        layout={WebinerLayout}
                    />
\
                    <WebinerRoute
                        path="/webinars/sessions/:coach_uid"
                        component={SessionDetails}
                        exact={true}
                        layout={WebinerLayout}
                    />
                    <WebinerRoute
                        path="/webinars/webinars-schedule"
                        component={Schedule}
                        exact={true}
                        layout={WebinerLayout}
                    />
                    <WebinerRoute
                        path="/webinars/webinars-details/:coach_uid/:id"
                        component={WebinerDetails}
                        exact={true}
                        layout={WebinerLayout}
                    />
                    <WebinerRoute
                        path="/webinars/webinars-history"
                        component={WebinarHistory}
                        exact={true}
                        layout={WebinerLayout}
                    />

                    {/* ________________auth_login__________________________ */}

                    <Route path="/login" component={Login} exact={true} />
                    <Route
                        path="/redirect-login"
                        component={RedirectLogin}
                        exact={true}
                    />
                    <Route
                        path="/forgotpassword"
                        component={ForgotPassword}
                        exact={true}
                    />
                    <Route path="/logout" component={Logout} exact={true} />
                    <Route
                        path="/ResetPassword/:token"
                        component={SetPassword}
                        exact={true}
                    />

                    {/* _______________auth_registration_____________________*/}

                    {/* <Route path="/registration" component={RegistrationStep} exact={true} />
                    <Route path="/coach_registration" component={CoachRegistration} exact={true} /> */}
                    <Route path="/registration" component={CoachRegistration} exact={true} />
                    <Route path="/account-activation/:token" component={Login} exact={true} />
                    <Route path="/user_registration" component={JobSeekerRegistration} exact={true} />
                    
                    {/* ____________________after_login_views_________________ */}

                    <Route
                        path="/profile-coach"
                        exact
                        render={(props) =>
                            localStorage.getItem("authToken") == null ? (
                                <>
                                    {console.warn(
                                        localStorage.setItem("externalHref", window.location.href)
                                    )}
                                    <Redirect to="/login" />
                                </>
                            ) : (
                                <Coach />
                            )
                        }
                    />
                    <Route
                        path="/profile-coach-edit"
                        exact
                        render={() =>
                            localStorage.getItem("authToken") == null ? (
                                <>
                                    {console.warn(
                                        localStorage.setItem("externalHref", window.location.href)
                                    )}
                                    <Redirect to="/login" />
                                </>
                            ) : (
                                <UpdatePersonalNew />
                            )
                        }
                    />

                    <Route
                        path="/reviews"
                        exact
                        render={() =>
                            localStorage.getItem("authToken") == null ? (
                                <>
                                    {console.warn(
                                        localStorage.setItem("externalHref", window.location.href)
                                    )}
                                    <Redirect to="/login" />
                                </>
                            ) : (
                                <ReviewList />
                            )
                        }
                    />

                    <Route
                        path="/authorize/:sessionID/:type"
                        component={Login}
                        exact={true}
                    />

                    <Route
                        path="/rejected/:sessionID/:type"
                        component={Login}
                        exact={true}
                    />

                    <Route path="/profile-coach-view" component={Coach} exact={true} />
                    <Route
                        path="/coach-profile/:username/:uuid"
                        component={CoachPublic}
                        exact={true}
                    />
                    <Route
                        path="/profile-coach-view/:uuid"
                        component={CoachProfilePrivate}
                        exact={true}
                    />
                    {/* <Route path="/profile-coach-view/:uuid" component={CoachPublic} exact={true} /> */}

                    {/* <Route path="/price_table" component={PriceTable} exact={true} /> */}
                    <Route path="/price_table" component={PriceTableNew} exact={true} />
                    <Route
                        path="/intro-session/:coach_uid/:package_uid"
                        component={BookSession}
                        exact={true}
                    />
                    <Route
                        path="/intro-booking"
                        component={BookingPayment}
                        exact={true}
                    />
                    <Route
                        path="/session-booking/:coach_uid/:package_uid"
                        component={MainSession}
                        exact={true}
                    />
                    <Route
                        path="/profile-consumer"
                        exact
                        render={() =>
                            localStorage.getItem("authToken") == null ? (
                                <>
                                    {console.warn(
                                        localStorage.setItem("externalHref", window.location.href)
                                    )}
                                    <Redirect to="/login" />
                                </>
                            ) : (
                                <>
                                    {localStorage.getItem("userdata") !== null &&
                                        JSON.parse(localStorage.getItem("userdata")).account_type ===
                                        "user" &&
                                        JSON.parse(localStorage.getItem("userdata")).is_approved ===
                                        "1" ? (
                                        <>
                                            <Consumer />
                                        </>
                                    ) : (
                                        <>
                                            <Redirect to="/coming-soon" />
                                        </>
                                    )}
                                </>
                            )
                        }
                    />
                    <Route
                        path="/coming-soon"
                        exact
                        render={() =>
                            localStorage.getItem("authToken") == null ? (
                                <>
                                    {console.warn(
                                        localStorage.setItem("externalHref", window.location.href)
                                    )}
                                    <Redirect to="/login" />
                                </>
                            ) : (
                                <Template />
                            )
                        }
                    />
                    {/* ____________________other_links_________________ */}

                    <Route path="/how-it-works" component={AboutUs} exact={true} />
                    <Route path="/pricing" component={Pricing} exact={true} />
                    <Route path="/webinars-details/:coach_uid/:id" component={WebinerDetailsPublic} exact={true} />
                    <Route path="/categories" component={Categories} exact={true} />
                    <Route path="/for-coaches" component={ForCoaches} exact={true} />
                    <Route path="/for-business" component={ForBusiness} exact={true} />
                    <Route
                        path="/packages_list"
                        component={ViewPackageList}
                        exact={true}
                    />
                    <Route path="/findmy_coach" component={FindMyCoach} exact={true} />
                    <Route path="/matchmy_coach" component={MatchMyCoach} exact={true} />
                    <Route path="/faq" component={FAQ} exact={true} />
                    <Route path="/contact_us" component={ContactUS} exact={true} />
                    <Route path="/webinars" component={Webinar} exact={true} />
                    <Route path="/terms" component={Terms} exact={true} />
                    <Route
                        path="/privacy-policy"
                        component={PrivacyPolicy}
                        exact={true}
                    />
                    <Route path="/gdpr" component={Gdpr} exact={true} />
                    <Route
                        path="/california-privacy-policy"
                        component={CaliforniaPrivacy}
                        exact={true}
                    />

                    <Route path="/feedback/:id" component={Feedback} exact={true} />

                    <Route
                        path="/profile-user-edit"
                        exact
                        render={() =>
                            localStorage.getItem("authToken") == null ? (
                                <>
                                    {console.warn(
                                        localStorage.setItem("externalHref", window.location.href)
                                    )}
                                    <Redirect to="/login" />
                                </>
                            ) : (
                                <UpdateUserPersonalnew />
                            )
                        }
                    />

                    {/* <Route path="/buy-subscription" component={Buysubscription} exact={true} /> */}
                    <Route
                        path="/buy-subscription/:planId"
                        component={Buysubscription}
                        exact={true}
                    />
                    <Route
                        path="/unsubscribe/:email"
                        component={Unsubscribe}
                        exact={true}
                    />
                    <Route path="/thankyou" component={ThankYou} exact={true} />
                    <Route
                        path="/resources-list/:type"
                        component={ResourcesList}
                        exact={true}
                    />
                    <Route path="/test" component={Testfile} exact={true} />

                    <Route
                        path="/sso-login"
                        exact={true}
                        render={(props) =>
                            new URLSearchParams(window.location.search).get("code") != null &&
                                new URLSearchParams(window.location.search).get("code") != "" ? (
                                <>
                                    <Redirect
                                        to={`/redirect-login?acode=${new URLSearchParams(
                                            window.location.search
                                        ).get("code")}&slug=${new URLSearchParams(
                                            window.location.search
                                        ).get("slug")}`}
                                    />
                                    {/* <Login {...props} /> */}
                                </>
                            ) : (
                                <Login {...props} />
                            )
                        }
                    />

                        <Route path="/ssoToken/:token" component={SSOTokkenHandle} exact={true} />
                </Switch>
            </ScrollToTop>
            <GoogleAnalyticsPageTracker />
        </>
    );
}

export function CorporateApp() {
    localStorage.setItem('subdomain', true)
    useEffect(() => {
        axios
            .get("/admin/settings/google_analytics_tracking_id")
            .then((res) => {
                if (res.status === 200) {
                    const data = res.data;
                    ReactGA.initialize(data.tracking_id);
                    ReactGA.pageview(window.location.pathname + window.location.search);
                }
            })
            .catch((e) => console.log(e));
    }, []);

    return (
        <ScrollToTop>
            <Coocki />
            <ScrollUpButton ShowAtPosition={200} style={{ width: 50, marginBottom: "70px" }} />
            <HelmetMetaData />
            <Switch>
                <Route path="/" component={Landing} exact={true} />
                <Route path="/webinars-category" component={Webinars_category} exact={true} />
                <Route path="/how-it-works" component={AboutUs} exact={true} />
                <Route path="/categories" component={Categories} exact={true} />
                <Route path="/for-coaches" component={CorporateForCoaches} exact={true} />
                <Route path="/faq" component={FAQ} exact={true} />
                <Route path="/contact_us" component={ContactUS} exact={true} />
                <Route path="/for-business" component={ForBusiness} exact={true} />
                <Route path="/signup" component={Signup} exact={true} />
                <Route path="/signin" component={CorporateLogin} exact={true} />
                <Route path="/dashboard" component={CorporateDashboard} exact={true} />
                <Route path="/webinar-details/:coach_uid/:id" component={WebinarDetails} exact={true} />
                <Route path="/my-transactions" component={MyTransaction} exact={true} />
                <Route path="/invite-employee" component={BulkContacts} exact={true} />
                <Route path="/account_tranfare/:account_id" component={AccountTransfare} exact={true} />
                <Route path="/setting" component={Setting} exact={true} />
                <Route path="/employeedetails/:group_id" component={BulkContactsDetails} exact={true} />
                <Route path="/employee-login" component={CorporateUserLogin} exact={true} />
                <Route path="/employee-signup" component={UserSignup} exact={true} />
                <Route path="/corporate_profile" component={profileindex} exact={true} />
                <Route path="/change-password" component={changePassword} exact={true} />
                
                <Route path="/sessions" component={CorporateSessions} exact={true} />
                <Route path="/webinar" component={CorporateWebinar} exact={true} />
                <WebinerRoute
                    path="/webinars"
                    component={Dashboard}
                    exact={true}
                    layout={WebinerLayout}
                />
                <WebinerRoute
                    path="/webinars/profile"
                    component={profileindex}
                    exact={true}
                    layout={WebinerLayout}
                />
                <WebinerRoute
                    path="/webinars/articles"
                    component={Articles}
                    exact={true}
                    layout={WebinerLayout}
                />
                <WebinerRoute
                    path="/webinars/sessions"
                    component={Sessions}
                    exact={true}
                    layout={WebinerLayout}
                />
                <WebinerRoute
                    path="/webinars/sessions/:coach_uid"
                    component={SessionDetails}
                    exact={true}
                    layout={WebinerLayout}
                />
                <WebinerRoute
                    path="/webinars/webinars-schedule"
                    component={Schedule}
                    exact={true}
                    layout={WebinerLayout}
                />
                <WebinerRoute
                    path="/webinars/webinars-details/:coach_uid/:id"
                    component={WebinerDetails}
                    exact={true}
                    layout={WebinerLayout}
                />
                <WebinerRoute
                    path="/webinars/webinars-history"
                    component={WebinarHistory}
                    exact={true}
                    layout={WebinerLayout}
                />
                <Route path="*" component={PageNotfound} />
            </Switch>
        </ScrollToTop>
    )
}
